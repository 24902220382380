import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'

export default class Banner extends Model {
    endpoint = 'banners'

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('text_es', 'Texto (español)'),
      new Field('text_en', 'Texto (inglés)'),
      new Field('start_date', 'Fecha de inicio', 'date'),
      new Field('end_date', 'Fecha de finalización', 'date'),
      new Field('order', 'Orden'),
    ]

    clone = () => Banner;
}
